//== BRAND COLORS
$sf-brand-green: #4ca42f;
$sf-brand-red: #dc0714;
$sf-brand-green-secondary: #075f20;
$sf-brand-brown: #643c00;
$sf-brand-grey: #9b9a99;
$sf-grey-borders: #e5e5e5;
$sf-navbar-bg: #353636;
$sf-navbar-border-color: #555;
$sf-light-grey: $sf-brand-grey;
$sf-dark-grey: $sf-navbar-bg;

//== IMAGES
$sf-navbar-logo-full: "/assets/icons/service2fruit/logo-full.svg";
$sf-navbar-logo-small: "/assets/icons/service2fruit/logo.svg";

//== override variables
$navbar-inverse-bg: $sf-navbar-bg;
$navbar-inverse-border: #434343;

$navbar-inverse-link-active-bg: #fff;
$navbar-inverse-link-color: #fff;
$navbar-inverse-link-active-color: #333;

$panel-border-radius: 0px;
$state-danger-text: $sf-brand-red;

$text-muted: $sf-light-grey;

$btn-default-bg: #ededed;
$btn-default-border: #bdbdbd;

$headings-font-family: -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
$headings-font-weight: 400;
$font-family-base: -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
$headings-color: $sf-navbar-bg;
$link-color: $sf-brand-green;
$link-hover-decoration: none;

//== ICON SIZES
.fa-4 {
  font-size: 4em !important;
}

.fa-3 {
  font-size: 3em !important;
}