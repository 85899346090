#myModal {
  justify-content: center;
}
.modal-content {
  border-radius: 2rem;
  border: 0;
  .modal-header {
    background-color: #35363A;
    border-radius: 2rem 2rem 0 0;
    padding: 2rem;

    .modal-title, button {
      color: white;
    }
  }
  .modal-body {
    padding: 2rem;
    display: grid;
    justify-items: center;
  }
  .modal-footer {
    background-color: white;
    border-radius: 2rem;
    button {
      background-color: $sf-brand-green;
      color: white;
      padding: 1rem 3rem;
      border-radius: 2rem;
    }
    #dontShowModal {
      background-color: transparent!important;
      color: black!important;
    }
    button:hover {
        background-color: #35363A;
    }
  }
}

.auction-card {
  position: relative;
  height: 370px;

  font-weight: 500;
  margin-bottom: 50px;
  border-radius: 8px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.auction-card .card-header {
  font-size: 16px;
  height: 4em;
  padding: 8px 1rem;
}

.auction-card .card-body {
  .auction-title {
    color: inherit;
    display: inline-block;
    padding: 0 1rem 1rem 1rem;
  }
}

.auction-card .card-footer {
  .btn-lg {
    border-radius: 0 0 8px 8px;
    position: absolute;
    bottom: 0;
  }
}

.auction-card .content {
  display: block;
  overflow: hidden;
}

.auction-card .timer {
  background: #eeeeee;
  border-radius: 11px;
  padding: 0 .75rem;
}

.count-0 {
  display: none;
}

.border-left {
  border-left: 1px solid $sf-brand-green;
}

.auction-card .content .btn {
  margin: 10px 10px 10px 10px;
}

.auction-card .image {
  background-color: lighten($sf-brand-green, 55%);
  border-radius: 8px 8px 0 0;
}

.auction-card a.item {
  padding: 0 10px;
}

.content p {
  margin: 10px 0px;
}

.auction-price {
  color: $sf-brand-green;
}

ul.auction-list li {
  border-top: 1px solid #ddd;
}
ul.auction-list li:last-child {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
ul.auction-list > li {
  margin-top: 20px;
}
ul.my-auction-list > li {
  border: 1px solid #ddd;
  margin-bottom: 15px;
}

table.auction-list-table td,
table.auction-list-table th {
  padding: 8px 10px;
  vertical-align: baseline;
}

table.auction-list-table td.auction-thumbnail,
table.table-auction-item-owner td.auction-thumbnail {
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
}

table.auction-list-table td.auction-thumbnail img,
table.table-auction-item-owner td.auction-thumbnail img {
  margin: -50%;
}

table.auction-list-table td h4 {
  margin-top: 5px;
}

.auction-card > .auction-title {
  display: block;
  height: 80px;
  padding: 5px;
}

.auction-card div.image-panel {
  background-color: #f2f2f2;
  height: 150px;
  overflow: hidden;
}

div.list-image {
  background-color: #f2f2f2;
  overflow: hidden;
  min-height: 100px;
  text-align: center;
}

.img-gallery {
  height: 150px;
}

.auction-card label {
  font-family: $headings-font-family;
  color: $sf-brand-green;
  font-size: 100%;
  text-transform: uppercase;
}

table.auction-details-table,
table.auction-list-table {
  width: 100%;
  table-layout: fixed;
}

/* Questions and Answers styling */
.panel-question {
  border: 1px solid #ddd;
  border-radius: 4px;
  border-left: 5px solid $sf-brand-red;
}

.panel-answered {
  border: 1px solid #ddd;
  border-radius: 4px;
  border-left: 5px solid $sf-brand-green;
}

.panel-question h6 {
  user-select: none;
  cursor: default;
}

.panel-question .question-title {
  font-weight: bold;
  margin-right: 1em;
}

.panel-question .question-author .question-user {
  color: $sf-brand-green;
  margin-right: 1em;
}

.panel-question .question-author .question-company {
  color: $sf-brand-green-secondary;
  margin-right: 1em;
}

.panel-question .question-time {
  font-style: italic;
}

.panel-question .question-disclaimer {
  font-style: italic;
  color: $alert-warning-text;
  display: block;
  margin-bottom: 0.8em;
}

/* File upload styling */
table.image-upload {
  margin-bottom: 0px;
}

table.image-upload tr td {
  border-top: none;
}

.file-label {
  border: 1px solid #d5d5d5;
  margin: 2px;
  display: inline-block;
  height: 150px;
  width: 140px;
  padding: 0;
  background: url("/assets/images/s2f-camera.jpg") center no-repeat #fff;
  overflow: hidden;
}

.file-label img {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.file-label:hover {
  border: 1px solid #4ca42f;
}

.file-label:active {
  background: #e5f2e5;
}

.file-label :invalid + span {
  color: #CC8E8E;
}

.file-label :valid + span {
  color: #8ecc8e;
}

input.fileinput-button {
  width: 0px;
  height: 0px;
  overflow: hidden;
  display: none;
}

/* End file upload styling */

/* style trade notes */
.s2f-notes-container {
  margin-bottom: -10px;
}

.s2f-notes-header {
  background: #fef9c8 url("/assets/images/push-pin.png") center center no-repeat;
  height: 32px;
  margin-top: 20px;
}

.s2f-notes-body {
  background-color: #fef9c8;
  padding: 15px;
}

.s2f-notes {
  padding-bottom: 10px;
}

.s2f-notes-footer {
  background: transparent url("/assets/images/notes-drop-shadow.png") center top no-repeat;
  /* not supported by all browsers: https://developer.mozilla.org/en-US/docs/Web/CSS/background-size#Browser_compatibility */
  background-size: 100% 100%;
  height: 15px;
}

/* end style trade notes */

/* Style auction category filter */
div.auction-filter-panel {
  display: flex;
  flex-flow: column nowrap;

  padding: 0px 0px 0px 20px;
  /* Change mouse pointer to checkbox*/
  label {
    cursor: pointer;
  }
  /* Set normal font weight */
  .checkbox {
    font-weight: normal;
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

/** move radio a little up */
.side-filters {
  div.radio > label > input {
    margin-top: 2px;
  }
}

/* end style auction category filter */

/* bid label */
.bid-label-panel {
  position: relative;
  text-align: left;
  z-index: 1;
}

.bid-label {
  white-space: normal;
  padding: 4px;
  border-radius: 0px;
  position: absolute;
  width: 100%;
  line-height: 1.4;
}

.auction-card .label {
    border-radius: 0 15px 15px 0;
    padding: .5rem 1rem .5rem 0;
    position: initial;
    white-space: normal;
    width: initial;
}

.auction-card .label::before {
    border-left: 2px solid;
    content: "";
    margin: .5rem 1rem .5rem 0;
}

.auction-card .label-primary {
    background: lighten(#337ab7, 40%);
    color: #337ab7;
}

.auction-card .label-success {
    background: lighten($sf-brand-green, 50%);
    color: $sf-brand-green;
}

.auction-card .label-warning {
    background: lighten(#f0ad4e, 30%);
    color: #f0ad4e;
}
/* end bid label */

/* my bid panel */

.my-bid-panel {
  .label {
    line-height: 1.3;
  }
}

/* end of my bid panel */

/* Make the auction counter in category filters a little greyer */
.category-counter {
  color: #999;
}

.table .border-bottom {
  border-bottom: 1px solid #ddd;
}

.table td.border-top {
  border-top: 1px solid #ddd;
}
//


// auction carousel
section.auction-carousel {
  background-color: #ddd;
}
#auction-carousel {
  height: 180px;
  overflow: hidden;
  @media(max-width: $screen-sm) {
    height: 130px;
  }
}
.navbar-auction {
  margin-bottom: 0px;
  background-color: #fff;
  border-bottom: 2px solid #eee;
  .navbar-collapse {
    padding: 0;
  }
}

.auction-dl.dl-horizontal {
  dt, dd {
    text-align: left;
    padding: 0.4rem 0;
    @media(min-width: $screen-sm) {
      border-top: 1px solid #ededed;
    }
    @media(max-width: $screen-xs) {
      padding: 0.3rem 0;
    }
  }
  dd {
    @media(min-width: $screen-sm) {
      margin-left: 50%;
    }
  }
  dt {
    color: #777;
    @media(max-width: $screen-sm-max) {
      border-top: 1px solid #ededed;
    }
    @media(min-width: $screen-sm) {
      width: 50%;
    }
  }
}

.text-nowrap {
  white-space: nowrap;
}

// remove margin from accept button for a bid
.form-panel > .form-group {
  margin-bottom: 0px;
}

// costs panel styling
.costs-panel {
  padding: 0 10px 0 10px;
}

ul.my-transaction-list > li {
  margin-bottom: 20px;
  border: 2px solid #ddd;
}

ul.sf-tabs-big > li > a,
ul.sf-tabs-big > li.active > a {
  border: none !important;
  border-radius: 0px !important;
}

#global-gap-block {
    border-left: 5px solid #f2f2f2;
}

#global-gap-block > .checkbox {
    margin-left: 30px;
}