footer {
  border-top: 1px solid $sf-grey-borders;
  background-color: #fafafa;
}

/* style logos in footer */
.footer-logo {
  margin: 15px auto 15px auto;
  width: 100%;
  max-width: 200px;
}

/* Show not active environment gray and on hover in color */
.footer-logo-notactive {
  filter: grayscale(100%);
}

.footer-logo-notactive:hover {
  filter: grayscale(0%);
}

/* Footer links should have white background and condensed padding */
.footer-links {
  background-color: #fff;
  font-size: 90%;
}