/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by acorovic, 2015-10-31
 */

/* remove border radius from navbar */
.navbar {
  border-radius: 0px;
  border: none;
}

/* Add our full logo to the navbar brand if user is not authenticated */
.navbar-guest .navbar-brand {
  background: transparent url($sf-navbar-logo-full) 15px center no-repeat;
  background-size: 150px;
  width: 180px;
  height: 60px;
}

/* If user is authenticated we show just the apple, to save space */
.navbar-authenticated .navbar-brand {
  background: transparent url($sf-navbar-logo-small) 15px center no-repeat;
  background-size: 35px;
  padding-left: 40px;
}

ul.nav {
  /* Style the bordered items */
  li.item-bordered {
    border-left: 1px solid $sf-navbar-border-color;
  }
}

.navbar-nav {
  li.active > a,
  li > a {
    &.freshmonitor-icon,
    &.active.freshmonitor-icon {
      background-image: url('/assets/images/freshmonitor/logo.svg');
      background-repeat: no-repeat;
      background-size: 30px;
      background-position: 10px center;
      padding-left: 48px;
    }
  }
}

/* Add borders to sign out icon */
ul.nav li.border-right {
  border-right: 1px solid $sf-navbar-border-color;
}

/* Make navbar-guest a little bigger, because our entire logo should fit nicely */
.navbar-guest .navbar-nav > li > a {
  line-height: 30px;
}

/* Style our own branded navbar */
.navbar-shadow {
  -webkit-box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
  -moz-box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
}

.nav-light li  a {
  color: #ddd;
}
/* Override to make the bell more yellow */
.nav li i.text-warning {
  color: #ff2;
}
/* When active make the yellow bell a bit darker */
.nav li.active i.text-warning {
  color: #D6D60F;
}

/* When on mobile override to make the yellow bell more yellow */
.menu-panel i.text-warning {
  color: #D6D60F;
}

/** Style search in nav bar */
form.navbar-form input {
  background-color: $sf-navbar-bg;
  outline: none;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  color: #dddddd;
}

form.navbar-form .btn {
  color: #dddddd;
  background-color: $sf-navbar-bg;
  border: none;

  i.fa-search {
    line-height: 1em;
    padding: 0 0 3px 0;
    font-size: 1em;
  }
}

h3.mobile-menu-username {
  border-bottom: 2px solid #d5d5d5;
  margin: 0px;
  padding: 10px 15px;
}

ul.mobile-menu {
  border-bottom: 2px solid #dedede;
  margin-bottom: 0px;

  li {
    text-align: center;
    width: 16%;
    padding-left: 0px;
    padding-right: 0px;
  }
  li.logout {
    width: 14%;
  }
  li .menu-item.border-right {
    border-right: 1px solid #dedede;

  }
  li .menu-item {
    text-align: center;
    a {
      line-height: 50px;
    }
  }
}

.navbar-toggle {
  background-color: #222;
}