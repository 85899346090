
@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_900.woff2") format("woff2"),url("fonts/proxima-nova/pnova_900.woff") format("woff"),url("fonts/proxima-nova/pnova_900.otf") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_900i.woff2") format("woff2"),url("fonts/proxima-nova/pnova_900i.woff") format("woff"),url("fonts/proxima-nova/pnova_900i.otf") format("opentype");
    font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_700.woff2") format("woff2"),url("fonts/proxima-nova/pnova_700.woff") format("woff"),url("fonts/proxima-nova/pnova_700.otf") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_700i.woff2") format("woff2"),url("fonts/proxima-nova/pnova_700i.woff") format("woff"),url("fonts/proxima-nova/pnova_700i.otf") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_800.woff2") format("woff2"),url("fonts/proxima-nova/pnova_800.woff") format("woff"),url("fonts/proxima-nova/pnova_800.otf") format("opentype");
    font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_800i.woff2") format("woff2"),url("fonts/proxima-nova/pnova_800i.woff") format("woff"),url("fonts/proxima-nova/pnova_800i.otf") format("opentype");
    font-display:auto;font-style:italic;font-weight:800;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_100.woff2") format("woff2"),url("fonts/proxima-nova/pnova_100.woff") format("woff"),url("fonts/proxima-nova/pnova_100.otf") format("opentype");
    font-display:auto;font-style:normal;font-weight:100;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_100i.woff2") format("woff2"),url("fonts/proxima-nova/pnova_100i.woff") format("woff"),url("fonts/proxima-nova/pnova_100i.otf") format("opentype");
    font-display:auto;font-style:italic;font-weight:100;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_600.woff2") format("woff2"),url("fonts/proxima-nova/pnova_600.woff") format("woff"),url("fonts/proxima-nova/pnova_600.otf") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_600i.woff2") format("woff2"),url("fonts/proxima-nova/pnova_600i.woff") format("woff"),url("fonts/proxima-nova/pnova_600i.otf") format("opentype");
    font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_400.woff2") format("woff2"),url("fonts/proxima-nova/pnova_400.woff") format("woff"),url("fonts/proxima-nova/pnova_400.otf") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_400i.woff2") format("woff2"),url("fonts/proxima-nova/pnova_400i.woff") format("woff"),url("fonts/proxima-nova/pnova_400i.otf") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_300.woff2") format("woff2"),url("fonts/proxima-nova/pnova_300.woff") format("woff"),url("fonts/proxima-nova/pnova_300.otf") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_300i.woff2") format("woff2"),url("fonts/proxima-nova/pnova_300i.woff") format("woff"),url("fonts/proxima-nova/pnova_300i.otf") format("opentype");
    font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_500.woff2") format("woff2"),url("fonts/proxima-nova/pnova_500.woff") format("woff"),url("fonts/proxima-nova/pnova_500.otf") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("fonts/proxima-nova/pnova_500i.woff2") format("woff2"),url("fonts/proxima-nova/pnova_500i.woff") format("woff"),url("fonts/proxima-nova/pnova_500i.otf") format("opentype");
    font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
}

