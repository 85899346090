/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by acorovic, 2015-10-31
 */

.fa-stack > i.fa-circle.fa-stack-2x {
  color: #eee;
}

.text-bold {
  font-weight: bold;
}

.white {
  color: #fff;
}

.green {
  color: $sf-brand-green;
}

.blue {
  color: $brand-primary;
}

.facebook-color {
  color: #3b5998;
}

.twitter-color {
  color: #1da1f2;
}

.instagram-color {
  color: #353636;
}

.linkedin-color {
  color: #0a66c2;
}

.whatsapp-color {
  color: #43d854;
}

.light-grey {
  color: #999999;
}

.yellow {
  color: yellow;
}

.purple {
  color: #6f5499;
}

.orange {
  color: #FF7F00;
}

.brown {
  color: #8B4513;
}

/* On sold page show the labels really small */
/* Personal auction items should also have a smaller font */
.table-auction-item-owner span.light-grey,
.table-sold-item span.light-grey {
  font-size: 75%;
}

/* highlight payment ref in a coding font to see diff between 0 and O */
code {
  padding: 0px;
  font-size: 100%;
  background-color: transparent;
  border-radius: 0px;
  color: $sf-dark-grey;
}

/* to prevent escaping html we use white space pre line */
.white-space-pre {
  white-space: pre-line;
}

.side-filters.auction-filters > h5 {
  padding-top: 5px;
}
.side-filters > h5 {
  font-weight: bold;
}

.text-smaller {
  font-size: 75%;
}
