/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by jzwart, 2016-10-17
 */

.nav-tabs.sf-tabs-big {
  :not(.active) a {
    background: #dddddd;
    color: inherit;
  }

  .fa:last-child {
    display: block;
    line-height: 32px;
  }

  & + .tab-content {
    min-height: 220px;
    padding: 10px 20px;
  }
}
