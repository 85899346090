// import our own variable to be able to override some
@import "fruit-variables";
// Core variables and mixins
@import "lib/bootstrap-sass/stylesheets/bootstrap/variables";
@import "lib/bootstrap-sass/stylesheets/bootstrap/mixins";
// Reset and dependencies
@import "lib/bootstrap-sass/stylesheets/bootstrap/normalize";
@import "lib/bootstrap-sass/stylesheets/bootstrap/print";
// Core CSS
@import "lib/bootstrap-sass/stylesheets/bootstrap/scaffolding";
@import "lib/bootstrap-sass/stylesheets/bootstrap/type";
@import "lib/bootstrap-sass/stylesheets/bootstrap/code";
@import "lib/bootstrap-sass/stylesheets/bootstrap/grid";
@import "lib/bootstrap-sass/stylesheets/bootstrap/tables";
@import "lib/bootstrap-sass/stylesheets/bootstrap/forms";
@import "lib/bootstrap-sass/stylesheets/bootstrap/buttons";
// Components
@import "lib/bootstrap-sass/stylesheets/bootstrap/component-animations";
@import "lib/bootstrap-sass/stylesheets/bootstrap/dropdowns";
@import "lib/bootstrap-sass/stylesheets/bootstrap/button-groups";
@import "lib/bootstrap-sass/stylesheets/bootstrap/input-groups";
@import "lib/bootstrap-sass/stylesheets/bootstrap/navs";
@import "lib/bootstrap-sass/stylesheets/bootstrap/navbar";
@import "lib/bootstrap-sass/stylesheets/bootstrap/breadcrumbs";
@import "lib/bootstrap-sass/stylesheets/bootstrap/pagination";
@import "lib/bootstrap-sass/stylesheets/bootstrap/pager";
@import "lib/bootstrap-sass/stylesheets/bootstrap/labels";
@import "lib/bootstrap-sass/stylesheets/bootstrap/badges";
@import "lib/bootstrap-sass/stylesheets/bootstrap/jumbotron";
@import "lib/bootstrap-sass/stylesheets/bootstrap/thumbnails";
@import "lib/bootstrap-sass/stylesheets/bootstrap/alerts";
@import "lib/bootstrap-sass/stylesheets/bootstrap/progress-bars";
@import "lib/bootstrap-sass/stylesheets/bootstrap/media";
@import "lib/bootstrap-sass/stylesheets/bootstrap/list-group";
@import "lib/bootstrap-sass/stylesheets/bootstrap/panels";
@import "lib/bootstrap-sass/stylesheets/bootstrap/responsive-embed";
@import "lib/bootstrap-sass/stylesheets/bootstrap/wells";
@import "lib/bootstrap-sass/stylesheets/bootstrap/close";
// Components w/ JavaScript
@import "lib/bootstrap-sass/stylesheets/bootstrap/modals";
@import "lib/bootstrap-sass/stylesheets/bootstrap/tooltip";
@import "lib/bootstrap-sass/stylesheets/bootstrap/popovers";
@import "lib/bootstrap-sass/stylesheets/bootstrap/carousel";
// Utility classes
@import "lib/bootstrap-sass/stylesheets/bootstrap/utilities";
@import "lib/bootstrap-sass/stylesheets/bootstrap/responsive-utilities";
@import "lib/font-awesome/scss/font-awesome";
// add flag support
@import "lib/flag-icon-css/sass/flag-icon";
// Service2Fruit Styling
@import "components/spacers";
@import "components/datepicker";
@import "frontend/typography";
@import "frontend/navbar";
@import "frontend/panels";
@import "frontend/buttons";
@import "frontend/footer";
@import "frontend/forms";
@import "frontend/auctions";
@import "frontend/countdown";
@import "frontend/profile";
@import "frontend/wizard";
@import "frontend/callouts";
@import "frontend/carousel";
@import "frontend/dossiers";
@import "frontend/style";
@import "components/badge";
@import "components/tabs";
@import "components/ribbon";
@import "components/tables";
@import "components/table-cards";
@import "frontend/banner";

// Big rebranding 2022
@import "rebrand";