/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by acorovic, 2015-10-31
 */

/*
 * We must allow buttons to be able to expand into two lines
 * if needed. Because some translations are too large!
 */
.btn {
  white-space: normal;
}

.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: all .5s;
}

.btn-primary.btn-outline {
  color: #428bca;
}

.btn-success.btn-outline {
  color: #5cb85c;
}

.btn-info.btn-outline {
  color: #5bc0de;
}

.btn-warning.btn-outline {
  color: #f0ad4e;
}

.btn-danger.btn-outline {
  color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}