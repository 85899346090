#canvas {
  background-color: #171717;
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.header-message {
  width: max-content;
  position: absolute;
  bottom: 5rem;
  left: 2rem;
  z-index: 6;
}

.rainbow-text {
  animation: rainbow-animation 1s infinite;
  font-family: "Snell Roundhand", cursive;
  font-size: 8rem;
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1280px) {

  .header-message {
    bottom: 5rem;
  }

  .rainbow-text {
    font-size: 8rem;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1024px to 1280px
*/
@media (min-width: 1024px) and (max-width: 1279px) {

  .header-message {
    bottom: 6rem;
  }

  .rainbow-text {
    font-size: 8rem;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1023px
*/
@media (min-width: 768px) and (max-width: 1023px) {

  .header-message {
    bottom: 28rem;
  }

  .rainbow-text {
    font-size: 7rem;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {

  .header-message {
    bottom: 28rem;
  }

  .rainbow-text {
    font-size: 7rem;
  }

}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .header-message {
    bottom: 48rem;
  }

  .rainbow-text {
    font-size: 4.5rem;
  }
}


/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  #s2t-banner-promo:after {
    left: 70%;
    border-right: 0;
  }

  .header-message {
    bottom: 48rem;
  }

  .rainbow-text {
    font-size: 4.5rem;
  }
}

@keyframes rainbow-animation {
  0% {
    color: #e87d7d;
  }
  2% {
    color: #e88a7d;
  }
  4% {
    color: #e8977d;
  }
  6% {
    color: #e8a47d;
  }
  8% {
    color: #e8b07d;
  }
  10% {
    color: #e8bd7d;
  }
  12% {
    color: #e8ca7d;
  }
  14% {
    color: #e8d77d;
  }
  16% {
    color: #e8e47d;
  }
  18% {
    color: #dfe87d;
  }
  20% {
    color: #d3e87d;
  }
  22% {
    color: #c6e87d;
  }
  24% {
    color: #b9e87d;
  }
  26% {
    color: #ace87d;
  }
  28% {
    color: #9fe87d;
  }
  30% {
    color: #92e87d;
  }
  32% {
    color: #86e87d;
  }
  34% {
    color: #7de881;
  }
  36% {
    color: #7de88e;
  }
  38% {
    color: #7de89b;
  }
  40% {
    color: #7de8a8;
  }
  42% {
    color: #7de8b5;
  }
  44% {
    color: #7de8c1;
  }
  46% {
    color: #7de8ce;
  }
  48% {
    color: #7de8db;
  }
  50% {
    color: #7de8e8;
  }
  52% {
    color: #7ddbe8;
  }
  54% {
    color: #7dcee8;
  }
  56% {
    color: #7dc1e8;
  }
  58% {
    color: #7db5e8;
  }
  60% {
    color: #7da8e8;
  }
  62% {
    color: #7d9be8;
  }
  64% {
    color: #7d8ee8;
  }
  66% {
    color: #7d81e8;
  }
  68% {
    color: #867de8;
  }
  70% {
    color: #927de8;
  }
  72% {
    color: #9f7de8;
  }
  74% {
    color: #ac7de8;
  }
  76% {
    color: #b97de8;
  }
  78% {
    color: #c67de8;
  }
  80% {
    color: #d37de8;
  }
  82% {
    color: #df7de8;
  }
  84% {
    color: #e87de4;
  }
  86% {
    color: #e87dd7;
  }
  88% {
    color: #e87dca;
  }
  90% {
    color: #e87dbd;
  }
  92% {
    color: #e87db0;
  }
  94% {
    color: #e87da4;
  }
  96% {
    color: #e87d97;
  }
  98% {
    color: #e87d8a;
  }
  100% {
    color: #e87d7d;
  }
}
