/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by acorovic, 2016-01-06
 */

input {
  accent-color: $sf-brand-green
}

.auction-wizard-form {
  margin-top: 20px;
  margin-bottom: 25px;
}

.auction-form h4 {
  line-height: 40px;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 10px;
  border-left: solid 1px #d5d5d5;
  border-top: solid 1px #d5d5d5;
  border-right: solid 1px #d5d5d5;
  border-bottom: solid 1px #d5d5d5;
  background-color: #fafafa;
}

.auction-wizard-form fieldset,
.auction-form fieldset {
  border: 1px solid #d5d5d5;
  padding: 20px;
  margin-bottom: -1px;
}

#residue-analysis-for-block {
  padding: 5px 5px 5px 20px;
}

/* set height to be same as input text field */
.checkbox.checkbox-toggle {
  min-height: 34px;
  margin-right: 10px;
}

.form-group.condensed {
  margin-bottom: 1px;
}

/* Fix styling for a custom field: http://adrianhurt.github.io/play-bootstrap/1.0-P24-B3/multifield/#collapse_java_messages_warning_3 */
.input-text-checkbox > .input-group-addon {
  .checkbox {
    display: inline;
  }
  label {
    display: inline;
    padding: 0;
    input {
      position: relative;
      margin-left: 0;
    }
  }
}

// Fix position of radioDatePicker field
.input-group-addon > label.radio-inline {
  padding-top: 0px;
}

// allow two fields in one input group to be placed
.duo-group .form-control {
  width: 49%;
  display: inline-block;
}

.input-group > select.form-control {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  -webkit-appearance: none;
}

.weekdays label {
    width: 17em;
}


// hint icons

.fa-info-circle {
    color: #31708f;
    line-height: 1.42857143;
    padding-top: 1px;
}

.fa-exclamation-circle {
    vertical-align: top;
}

.control-label > .fa-info-circle {
    @media (min-width: $screen-sm-min) {
        bottom: 0;
        position: absolute;
        margin-left: 3px;
    }
}

/*
 * Google's reCAPTCHA badge is placed in de bottom right corner,
 * underneath the footer. Therefore, increase z-index to make
 * the badge visible.
 *
 * See: https://github.com/service2fruit/Elstar/issues/557
 */
.grecaptcha-badge {
  z-index: 10;
}
