.ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#F70505 0%, #8F0808 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #8F0808;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8F0808;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #8F0808;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8F0808;
}
.ribbon-green span {
  background: linear-gradient($sf-brand-green 0%, darken($sf-brand-green, 15%) 100%);
}
.ribbon-green span::before {
  border-left: 3px solid darken($sf-brand-green, 10%);
  border-top: 3px solid darken($sf-brand-green, 10%);
}
.ribbon-green span::after {
  border-right: 3px solid darken($sf-brand-green, 10%);
  border-top: 3px solid darken($sf-brand-green, 10%);
}
.ribbon-blue span {
  background: linear-gradient(#337ab7 0%, darken(#337ab7, 15%) 100%);
}
.ribbon-blue span::before {
  border-left: 3px solid darken(#337ab7, 10%);
  border-top: 3px solid darken(#337ab7, 10%);
}
.ribbon-blue span::after {
  border-right: 3px solid darken(#337ab7, 10%);
  border-top: 3px solid darken(#337ab7, 10%);
}
.ribbon-panel {
  right: 10px;
}
.ribbon-list-item {
  top: auto;
  margin-top: -5px;
  margin-right: 20px;
}