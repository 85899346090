/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by tgaric, 2018-07-09
 */

.border-bottom-admin {
  border-bottom: solid 4px $brand-success;
}

.border-bottom-seller {
  border-bottom: solid 4px $brand-info;
}

.border-bottom-buyer {
  border-bottom: solid 4px $brand-warning;
}

.timeline-panel-buyer {
  right: -2px;
  border-right: dashed 2px #ddd;
}

.timeline-panel-seller {
  border-left: dashed 2px #ddd;
}

.timeline-panel-buyer > i {
  right: -7px;
  padding-top: 12px;
  position: absolute;
}

.timeline-panel-seller > i {
  left: -7px;
  padding-top: 12px;
  position: absolute;
}

.timeline-date {
  padding-top: 10px;
}

@media(max-width: 992px) {
  .timeline-date {
    text-align: left !important;
  }

  .timeline-panel-buyer, .timeline-panel-admin, .timeline-panel-seller, .timeline-date {
    margin-left: 15px;
    border-left: dashed 2px #ddd;
    border-right: none;
    right: 0;
  }

  .timeline-panel-buyer > i {
    left: -7px;
  }

  .timeline-panel-admin > i {
    padding-top: 12px;
    left: -7px;
    position: absolute;
  }
}