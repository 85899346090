@media screen and (max-width: $screen-md) {
  .table-cards {

    thead {
      display: none;
    }

    /* card */
    tbody tr {
      border: 1px solid #ccc;
      display: block;
      padding: 1rem;
      margin-bottom: 1rem;
      overflow: hidden;
    }

    /* card row */
    tbody td {
      display: block;
      overflow: hidden;
      padding-bottom: 1rem;
      text-align: left;

      &[data-label]::before {
        content: attr(data-label);
        display: block;
        font-weight: bold;
        overflow: hidden;
      }
    }
  }
}