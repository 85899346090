/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by acorovic, 2015-09-14
 */

.sf-tabs {
  margin-bottom: 20px;
}

.sf-tabs li.active a,
.sf-tabs li.active a:link,
.sf-tabs li.active a:active,
.sf-tabs li.active a:hover {
  border-top: 3px solid $sf-brand-green;
}

.sf-small {
  margin-bottom: 0px;
  color: $sf-brand-grey;
}

.list-group-header {
  padding-top: 18px;
  padding-bottom: 23px;
}

.list-group .active,
.list-group .active:active,
.list-group .active:link,
.list-group .active:hover {
  background-color: #fff;
  border-color: #dddddd;
  color: #333;
  border-left: 3px solid $sf-brand-green !important;
}

/** Message center styling */
ul.messages-list {
  margin-bottom: 0px;
}

ul.messages-list li:hover {
  background-color: #fafafa;
}

ul.messages-list li .block-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

ul.messages-list li:last-child .block-item {
  border-bottom: none;
}

ul.messages-list li a {
  padding-left: 5px;
}

/** align message marker with message link */
.message-marker {
  vertical-align: top;
}

ul.auction-buttons {
  float: right;
  height: 30px;
}
/** remove right padding */
ul.auction-buttons > li:last-child {
  padding-right: 0px;
}
/** Style profile shortcut items */
div.profile-shortcuts > a {
  padding-bottom: 15px;
  display: block;
}
div.profile-shortcuts > a:hover {
  background-color: #f5f5f5;
}
div.profile-shortcuts > a:hover > * {
  color: $sf-brand-green;
}
div.profile-shortcuts > a:hover .fa {
  color: $sf-brand-red;
}

/** Circles */
span.tooltip-wrapper {
  display: block;
  position: relative;
}

span.tooltip-wrapper > .wrapped-tooltip[data-toggle=tooltip] {
  z-index: 1;
  display: block;
  position: absolute;
  cursor: not-allowed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

span.tooltip-wrapper > button[data-toggle=tooltip] {
  z-index: 0;
  position: relative;
}

td.circle-list-name {
  width: 70%;
}

td.circle-list-count {
  width: 5%;
}

td.circle-member-name, td.circle-member-company, td.blocklist-user, td.blocklist-company {
  width: 42%;
}

td.circle-list-count, td.circle-list-actions, td.circle-member-actions, td.blocklist-actions {
  text-align: right;
}

a.circle-rename-button, span.circle-delete-button-wrapper {
  margin-top: 20px;
}

div.circle-action-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

/** Move to more general place when reusing this select template */
span.usercompany-row {
  display: flex;
  flex-flow: row nowrap;
}

span.usercompany-row > span.usercompany-user {
  display: inline-block;
  width: 50%;
  margin-right: 1em;
}

span.usercompany-row > span.usercompany-company {
  display: inline-block;
  width: 50%;
}

span.usercompany-row > i.fa {
  font-size: 1.1em;
  margin-right: 0.5em;
  position: relative;
  top: 0.12em;
}