@import "fonts/proxima-nova/pnova";

$s2f-brand-charcoal: #2F4D63;
$s2f-brand-yellow: #F3931B;
$s2f-brand-magenta: #9F4074;
$s2f-brand-white: #FFFFFF;
$s2f-brand-black: #000000;
$s2f-brand-light-gray: #F6F7F8;


$headings-font-family: "proxima-nova",sans-serif;
$font-family-base: "proxima-nova",sans-serif;

$header-height: 100px;

body {
  color: $s2f-brand-charcoal;
  font-family: $font-family-base;

  padding-top: $header-height;
}

a {
  color: $s2f-brand-yellow;
  text-decoration: none;

  &:hover, &:active {
    color: $s2f-brand-yellow;
    text-decoration: none;
  }
}

/* generic brand colors */
.brand-yellow {
  color: $s2f-brand-yellow;
}

.brand-charcoal {
  color: $s2f-brand-charcoal;
}

.brand-magenta {
  color: $s2f-brand-magenta;
}

/* Header */
nav.navbar {
  background-color: $s2f-brand-white;
  color: $s2f-brand-charcoal;
  min-height: $header-height;
}

.navbar-inverse .navbar-nav > li > a {
  color: $s2f-brand-charcoal;

  font-weight: 600;
  font-size: 14px;
  line-height: 40px;

  &:hover, &:active {
    color: $s2f-brand-magenta;
  }
}

.notification-bell, .notification-star {
  color: $s2f-brand-yellow;
}

.badge.badge-notification {
  background-color: $s2f-brand-magenta;
}

ul.navbar-nav {
  height: $header-height;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

/* Footer */
#footer, .footer-links {
  background-color: $s2f-brand-charcoal;
  color: $s2f-brand-white;
  padding-top: 3em;
}


/* logos */
.navbar-authenticated .navbar-brand, .navbar-guest .navbar-brand {
  background: transparent url("/assets/icons/service2fruit/s2f_logo_color_borderless.svg") center no-repeat;
  background-size: 70px;
  padding-left: 40px;
  height: $header-height;
  width: $header-height;
}

/* Wizard steps */
ul.nav-wizard, ul.nav-wizard li > a, ul.nav-wizard .active ~ li > a, ul.nav-wizard .active ~ li {
  background-color: $s2f-brand-charcoal;
  color: $s2f-brand-white;
}

ul.nav-wizard .active ~ li a, ul.nav-wizard .active ~ li a:active, ul.nav-wizard .active ~ li a:visited, ul.nav-wizard .active ~ li a:focus
{
  background-color: $s2f-brand-charcoal;
  color: $s2f-brand-light-gray;
}
ul.nav-wizard > li::after, ul.nav-wizard .active ~ li::after {
  border-left-color: $s2f-brand-charcoal;
}

ul.nav-wizard li.active::after {
  border-left-color: $s2f-brand-magenta;
}

ul.nav-wizard li.active a, ul.nav-wizard li.active a:active, ul.nav-wizard li.active a:visited, ul.nav-wizard li.active a:focus,
ul.nav-wizard li.active {
  background-color: $s2f-brand-magenta;
  color: $s2f-brand-white;
}

/* Wizard */
.callout-info {
  border-left-color: $s2f-brand-magenta;
}

/* Bootstrap rebranding */

.btn {
  border-radius: 0 0 15px 0;
  background-color: $s2f-brand-yellow;
  color: $s2f-brand-white;
  font-weight: normal;
  font-size: 20px;
  text-align: center;

  padding: 0.1em 0.6em;
  cursor: pointer;

  border: none;

  &.btn-secondary {
    background-color: $s2f-brand-magenta;


    &:hover, &:active, &:focus {
      background-color: $s2f-brand-magenta;
      color: $s2f-brand-white;
      outline: none;
    }
  }
}

.btn-danger {
  background-color: #d9534f;
}

.btn:hover {
  background-color: $s2f-brand-yellow;
}

.btn.btn-lg {
  border-radius: 0 0 30px 0;
  font-size: 25px;
  padding: 0.2em 1.4em;
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 2rem;
  margin-top: 2rem;
}

input {
  accent-color: $s2f-brand-charcoal;
}