/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by acorovic, 2015-08-10
 */

.countdown-holding span {
  color: #888;
}

.countdown-row {
  clear: both;
  width: 100%;
  text-align: center;
}

.countdown-show1 .countdown-section {
  width: 98%;
}
.countdown-show2 .countdown-section {
  width: 48%;
}
.countdown-show3 .countdown-section {
  width: 32.5%;
}
.countdown-show4 .countdown-section {
  width: 24.5%;
}
.countdown-show5 .countdown-section {
  width: 19.5%;
}
.countdown-show6 .countdown-section {
  width: 16.25%;
}
.countdown-show7 .countdown-section {
  width: 14%;
}
.countdown-section {
  display: block;
  float: left;
  text-align: center;
}
.countdown-amount {
  font-family: $headings-font-family;
  font-size: 150%;
}
.countdown-period {
  display: block;
  font-size: 75%;
}