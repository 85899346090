/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by acorovic, 2015-11-12
 */
//===============================================
// Variables
//===============================================
$wizard-background: #353636;
$border-color: transparent;

$complete-color: #fff;
$complete-background: #468847;

$active-color: #fff;
$active-background: $sf-brand-green;

$incomplete-color: lighten(#000, 60%);
$incomplete-background: $sf-navbar-bg;
$row-height: 50px;

$wizard-form-border-color: #ddd;

//===============================================
// Form
//===============================================

.form-wizard {
  padding: 30px 25px 25px 25px;
  border-left: 1px solid $wizard-form-border-color;
  border-bottom: 1px solid $wizard-form-border-color;
  border-right: 1px solid $wizard-form-border-color;
}

.help-wizard {
  padding-left: 20px;
}

//===============================================
// Mixins
//===============================================

@mixin li-color($color, $background) {
  color: $color;
  background: $background;
  &:after {
    border-left: 16px solid $background;
  }
  a, a:active, a:visited, a:focus {
    color: $color;
    background: $background;
  }
}

@mixin chevron-comp($color) {
  position: absolute;
  display: block;
  border: 26px solid transparent;
  border-left: 16px solid $color;
  border-right: 0;
  top: -1px;
  z-index: 10;
  content: '';
}

//===============================================
// Class Def
//===============================================

ul.nav-wizard {
  background-color: $wizard-background;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  *zoom: 1;
  position: relative;
  overflow: hidden;
  &:before {
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    top: $row-height;
    height: $row-height + 1px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    z-index: 11;
    content: " ";
  }
  &:after {
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 3 * ($row-height);
    height: $row-height + 1px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    z-index: 11;
    content: " ";
  }
  li {
    position: relative;
    float: left;
    height: $row-height;
    display: inline-block;
    text-align: center;
    padding: 0 20px 0 30px;
    margin: 0;
    line-height: $row-height;
    a {
      color: $complete-color;
      padding: 0;
      &:hover {
        background-color: transparent;
      }
    }
    &:before {
      @include chevron-comp($border-color);
      right: -16px;
    }
    &:after {
      @include chevron-comp($wizard-background);
      right: -15px;
    }
    &.active {
      @include li-color($active-color, $active-background);
    }
  }
  &.col-8 li {
    width: (100% / 8);
  }
  &.col-4 li {
    width: (100% / 4);
  }
  .active ~ li {
    @include li-color($incomplete-color, $incomplete-background);
  }
  &.nav-wizard-backnav {
    li:hover {
      @include li-color($complete-color, lighten($active-background, 7%));
    }
    .active ~ li {
      @include li-color($incomplete-color, $incomplete-background);
    }
  }
}
/** style auction step summary */
.wizard-step-summary {
  background-color: #eee;
  padding-top: 15px;
  margin: 0px 0 15px 0;
}