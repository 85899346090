/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by acorovic, 2015-10-31
 */

/* Headliner panel should be at least 350px height */
.panel-headliner {
  min-height: 350px;
  background-size: cover;
}

/* Add some padding to our headliner panel, makes our content not hit the edges */
.panel-headliner-content {
  padding: 15px 0 15px 0;
}