/* Always show the scrollbar, this way we prevent screen from jumping */
body {
  overflow-y: scroll;
}
// if we have no auction to push the footer a little down
main.container {
  min-height: 750px;
}

.forgot-password {
  font-size: 12px;
}

.text-dark-grey {
  color: $sf-dark-grey;
}

.text-light-grey {
  color: $sf-light-grey;
}

/* remove border radius from flash panels */
.flash-section {
  border-radius: 0px;
}

div.user-info-block,
div.search-block {
  padding: 5px 0 5px 0;
}

div.user-info-block {
  width: 200px;

  /* align text on the buttons */
  .text-left {
    text-align: left !important;
  }
}

.auction-tile {
  height: 300px;
}

/* Style alert to be nice alligned in center */
.flash-section {
  margin-bottom: 0px !important;
  position: fixed;
  width: 100%;
  z-index: 5
}

.alert-content {
  padding: 0 10px 0 10px;
}

div.side-filters {
  margin-top: 20px;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  font-size: 90%;
}

.transaction-filters,
.auction-filters {
  padding: 5px 10px 10px 10px;
}

// show filter on devices that are bigger then md
@media (min-width: $screen-md)
{
  #filters
  {
    display: block;
  }
}

.pagetop.pagination {
  margin-bottom: 0px;
}

/** Apply top margin for panels and define margin utilities */
.top-margin,
.panel-top-margin {
  margin-top: 20px;
}

.bottom-margin,
.panel-bottom-margin {
  margin-bottom: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

/** Style the services list */
div.services-filter h4 {
  padding: 5px 10px 5px 10px;
}

ul.services-list li a {
  line-height: 40px;
  padding: 5px 10px 0 10px;
}

ul.services-list li.active {
  background-color: #fff;
  border-left: 3px solid green;
}

ul.services-list li.active a.clear-filter {
  line-height: 30px;
}

/** remove borders from services table */
table.services-table tr td {
  border-top: none;
}

/** add bottom border to services row */
.services-row {
  border-bottom: 1px solid #dddddd;
  margin: 5px 0 5px 0;
}

ul li .block-item {
  display: block;
}

.services-image-holder {
  height: 144px;
}

.services-image-holder img {
  max-height: 100%;
  max-width: 100%;
}

/* Tables styles */
.table-bidlist {
  margin-bottom: 0px;
}
.table-partial-bid-list {
  margin-top: 15px;
  border-top: 1px solid #ddd;
}
.table-borderless > tbody > tr > th,
.table-borderless > tbody > tr > td {
  border: none;
}
.table-ultra-condensed {
  margin-bottom: 0px;
}
.table-ultra-condensed > tbody > tr > td,
.table-ultra-condensed > tbody > tr > th {
  padding: 1px;
}
.table-bordered-bottom {
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 10px;
}
.table-transparent {
  background-color: transparent !important;
}

/* Sold items and owner auctions table should not have a bottom margin */
.table-auction-item-owner,
.table-sold-item {
  margin-bottom: 0px;
}
td.vertical-align-middle,
th.vertical-align-middle {
  vertical-align: middle !important;
}

/** Utility for fixed table layouts */
.table-fixed {
  table-layout: fixed;
}
td.main-column, th.main-column {
  width: 60%;
}
td.action-column, th.action-column {
  width: 100px;
}
th.action-column-3, td.action-column-3 {
  width: 125px;
}

hr.condensed {
  border: 1px solid transparent;
}
hr.condensed-line {
  margin: 0px 0 10px 0;
}

/** add page spacer with grey background */
.sf-page-header-spacer {
  height: 120px;
  padding-bottom: 80px;
  background-color: #fafafa;
  border-bottom: 1px solid #d5d5d5;
}
/** Add sf icon to background */
.sf-page-bg {
  background: #fafafa url('/assets/icons/apple-grey-transparent.png');
}

/** sf content should be overlapping the page header */
.sf-page-content {
  border: 1px solid #d5d5d5;
  height: 400px;
  margin-top: -80px;
  background-color: #fff;
}

.bottom-marginless {
  margin-bottom: 0px;
}

// terms and conditions
.s2f-terms-embed {
  height: 450px;
  overflow: auto;

  .embed-responsive-item {
    overflow: auto;
  }
}

.padding-less {
  padding: 0px;
}

// custom styling for user transactions
tr.total-line > th, tr.total-line > td {
  border-top: 2px solid #555 !important;
}

.section-refreshed {
    box-shadow: 0 0 8px #ffb100
}

.alert-button-confirm {
  background-color: green;
}

.alert-button-confirm:not([disabled]):hover {
  background-color: $brand-success;
}

.swal-icon {
  border-color: green;
}

.swal-icon--info:before {
  background-color: green;
}

.swal-icon--info:after {
  background-color: green;
}

.airy {
    dt {
        margin-top: 3rem
    }
    dd {
        margin-top: 1rem
    }
}