/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by acorovic, 2015-11-14
 */
.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}

.callout .fa-stack {
  margin-right: 15px;
}

.callout-info {
  border-left-color: $brand-primary;
}

.callout-warning {
  border-left-color: $brand-warning;
}

.callout-success {
  border-left-color: $brand-success;
}

.callout-danger {
  border-left-color: $brand-danger;
}