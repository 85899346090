/*
 * Copyright (C) Service2Fruit B.V. - All Rights Reserved
 * Unauthorized copying of (parts of) this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by acorovic, 2015-09-11
 */

.carousel-item-fill {
  width: 100%;
  height: 100%;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.carousel-inner > .item > img {
  margin-left: auto;
  margin-right: auto;
  height: 150px;
}

h1.headliner {
  color: #fff;
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h1 * 1.1;
    margin-top: 120px;
  }
  @media (min-width: $screen-md-min) {
    margin-top: 100px;
    font-size: $font-size-h1 * 1.3;
  }
  @media (min-width: $screen-lg-min) {
    margin-top: 100px;
    font-size: $font-size-h1 * 1.5;
  }
  font-weight: 700;
}

div.register-panel {
  margin-top: -100px;
  height: 100px;
  padding: 1px 20px 5px 20px;
}

h1.headliner.white {
  color: #fff;
}

h1.headliner.grey {
  color: #3d3d3d;
}

h1.headliner.red {
  color: #a02e2e;
}

h1.headliner.capital {
  text-transform: uppercase;
  text-rendering: geometricPrecision;
  text-shadow: #989898 1px 1px;
}

/*
 * The following are style rules for our (bootstrap) carousel on the homepage, when the user is logged in.
 */

.landing-carousel {
  position: relative;
}

.landing-carousel > .carousel-inner > .item {
  height: 150px;
  background-color: #f2f2f2;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
}

.landing-carousel .header-buttons {
  position: absolute;

  display: flex;
  flex-flow: row wrap;
  column-gap: 2em;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  pointer-events: none;
}

.landing-carousel .header-buttons > * {
  pointer-events: auto;
}


@media only screen and (min-height: 800px) {
  .landing-carousel > .carousel-inner > .item {
    height: 600px;
  }

  .landing-carousel .header-buttons {
    align-items: flex-end;
    padding-bottom: 7em;
  }
}

/* Make carousel items on auction page unselectable */
.owl-carousel .owl-item {
  user-select: none;
}